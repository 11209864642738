export const Header = () => {
  return (
    <header className="bg-second font-bold  text-main">
      <div className="container space-x-[16px] pt-[70px] lg:pt-[150px]">
        <a
          href="https://www.instagram.com/friends_n_hands"
          target="_blank"
          className="border-b-2 border-main  hover:border-[#525252] hover:text-[#525252]"
        >
          IG
        </a>

        <a
          href="https://t.me/friends_hands"
          target="_blank"
          className="border-b-2 border-main  hover:border-[#525252] hover:text-[#525252]"
        >
          TG
        </a>

        <a
          href="https://www.behance.net/friendshands"
          target="_blank"
          className="border-b-2 border-main  hover:border-[#525252] hover:text-[#525252]"
        >
          BE
        </a>
      </div>
    </header>
  );
};
