import React from 'react';
import Image from 'next/image';
import { GetServerSideProps } from 'next/types';
import { allSettled, fork, serialize } from 'effector';

import { MainLayout } from '@/layouts/main-layout';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/shared/components/ui/accordion';
import { WordRotate } from '@/shared/components/ui/word-rotate';
import { EFFECTOR_STATE_KEY } from '@/shared/lib/effector';
import { PageSeo } from '@/shared/lib/seo';

import { indexPage } from './index.model';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const scope = fork();

  await allSettled(indexPage.open, { scope, params: {} });

  return {
    props: {
      [EFFECTOR_STATE_KEY]: serialize(scope),
    },
  };
};

const HomePage = () => {
  return (
    <>
      <PageSeo title={''} />

      <MainLayout>
        <div className="container flex flex-col font-bold">
          <div className="mx-auto mt-[70px] flex flex-col xl:mt-[150px]">
            <Image alt="логотип" src="/images/logo.svg" height={160} width={328} />
            <div className="mt-[10px] whitespace-nowrap text-right text-[24px] uppercase">
              <WordRotate
                words={[
                  <div key={''} className="leading-[26px]">
                    Агентство
                    <br />
                    спецпроектов
                  </div>,

                  <div key={''} className="leading-[26px]">
                    Креативная
                    <br />
                    студия
                  </div>,

                  <div key={''} className="leading-[26px]">
                    <span>Создавайте</span>
                    <br />с нами
                  </div>,

                  <div key={''} className="leading-[26px]">
                    Create
                    <br />
                    with us
                  </div>,
                ]}
              />
            </div>
          </div>
          <div className="relative mt-[70px] flex flex-col justify-between text-[16px] leading-[20px] lg:mt-[150px] lg:flex-row">
            <div className="max-w-[500px] lg:mb-20 lg:mt-[70px]">
              Каждый аспект нашей работы направлен на создание уникального и запоминающегося образа бренда.
              <br />
              <br />
              Мы уделяем максимум внимания деталям, начиная с концепции и заканчивая визуальным оформлением и
              коммуникациями.
              <br />
              <br />
              Наша цель – вдохновить и заинтересовать людей, чтобы бренд оставался актуальным и запоминающимся на долгие
              годы.
            </div>
            <div className="mb-20 mt-6 block lg:hidden">
              <a
                href="https://t.me/nastyastepanova"
                className=" border-b-2 border-main text-[14px] uppercase hover:border-[#525252] hover:text-[#525252]"
                target="_blank"
              >
                Связаться с нами
              </a>
            </div>
            <div className="mb-[-30px] ml-auto xl:absolute xl:bottom-[-30px] xl:right-0 xl:mb-0">
              <Image alt="" src="/images/star.svg" height={280} width={460} />
            </div>
          </div>
        </div>
        <div className="bg-main pt-[80px] font-bold text-second ">
          <div className="container">
            <div className="flex flex-col xl:grid xl:grid-cols-3  ">
              <div>
                <div>
                  <p>
                    Креативные концепции
                    <br />
                    Позиционирование <br />
                    Брендинг
                    <br /> Дизайн
                    <br /> Мероприятия
                    <br />
                    Интеграции и активации
                  </p>
                </div>
                <div className="mt-[100px] hidden lg:block">
                  <a
                    href="https://t.me/nastyastepanova"
                    className=" border-b-2 border-second text-[14px] uppercase hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                    target="_blank"
                  >
                    Связаться с нами
                  </a>
                </div>
              </div>
              <div className="xl:col-span-2 xl:pr-[130px] ">
                <Accordion type="single" collapsible>
                  <AccordionItem value="item-1" className="text-right ">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Медиа о здоровье глаз «Клуб хорошего зрения»
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>Мы запустили проект для «Оптик-Центр», который нацелен помогать людям жить лучше.</p>
                      <p>В первую очередь – в вопросах, касающихся здоровья глаз.</p>
                      <br />
                      <p>
                        Результаты проекта за год: более 140 тыс. подписчиков и более 20 млн просмотров reels, а также
                        более 2 500 подписчиков на Дзен и 4 200 часов просмотров статей и видео.
                      </p>
                      <br />
                      <p>
                        Для коммуникации с подписчиками мы подключили виртуального помощника на базе ИИ, который
                        отвечает на все вопросы о здоровье глаз и зрении как компетентный специалист в области
                        офтальмологии.{' '}
                      </p>
                      <br />
                      <p>
                        В среднем 10 уникальных пользователей в сутки обращается за бесплатной консультацией к
                        виртуальному помощнику.
                      </p>
                      <br />
                      <div className="space-x-2">
                        {' '}
                        Ознакомиться с проектом:{' '}
                        <a
                          href="https://www.instagram.com/klubhoroshegozreniya"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          IG
                        </a>{' '}
                        <a
                          href="https://dzen.ru/khz"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          DZEN
                        </a>{' '}
                        <a
                          href="https://vk.com/klubhoroshegozreniya"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          VK
                        </a>{' '}
                        <a
                          href="https://www.youtube.com/channel/UCaBq9S52BrJ9KGkJdiILClg"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          YT
                        </a>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-2" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Позиционирование офтальмологической компании «Оптик-Центр»
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        Офтальмологическая компания «Оптик-Центр» находит новые возможности в офтальмологии, чтобы
                        каждый человек мог увидеть свои возможности в жизни. «Оптик-Центр»: мир такой, каким ты его
                        видишь.
                      </p>
                      <br />
                      <div>
                        Смотреть проект:{' '}
                        <a
                          href="https://docs.google.com/presentation/d/1Nfm5M7HBtkqlvULH4xBAzZ_YM5UDP6v6V7j-LUAFBvg/edit?usp=sharing"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-3" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Позиционирование и брендинг инклюзивного пространства «Наше место»
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        Инклюзивное пространство «Наше место» даёт каждому человеку возможность быть частью большого
                        мира через их собственное желания и действия. «Наше место» – мир, где ты можешь.
                      </p>
                      <br />
                      <div>
                        Смотреть проект:{' '}
                        <a
                          href="https://disk.yandex.ru/i/uuKR4vcFyrbSgQ"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-4" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Позиционирование медицинского центра NEWMED
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        Заботиться о своем здоровье – это осознанный выбор каждого. NEWMED разделяет выбор людей и
                        помогает им в этом. Забота о здоровье – это выбор. Выбирайте NEWMED.
                      </p>
                      <br />
                      <div>
                        Смотреть проект:{' '}
                        <a
                          href="https://disk.yandex.ru/i/Y7eXKfyL4p0N1Q"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-5" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Рекламный контент для образовательной платформы Maximum Education
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        Разработка креативной концепции рекламной кампании и создание видеороликов, диджитал-баннеров и
                        аудиороликов для образовательного проекта.
                      </p>
                      <br />
                      <div>
                        Смотреть проект:{' '}
                        <a
                          href="https://disk.yandex.ru/d/5XUavxDlDrfchA"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-6" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Разработка и проведение клиентских мероприятий для Ray-Ban и Lanvin
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        Разработка и реализация мероприятий под ключ для мировых брендов очков Ray-Ban и Lanvin с целью
                        презентаций новых коллекций.
                      </p>
                      <br />
                      <div>
                        Как прошло мероприятие для Ray-Ban смотрите{' '}
                        <a
                          href="https://www.youtube.com/watch?v=GnJCnbP1erE"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                      <div>
                        Как прошло мероприятие для Lanvin смотрите :{' '}
                        <a
                          href="https://vk.com/optic_center?w=wall-38534449_17443"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-7" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Участие в организации большой конференции маркетологов на Урале «ЗАВОД»
                    </AccordionTrigger>
                    <AccordionContent>
                      <p className="">
                        Наша команда совместно с клубом маркетологов и сервисологов на Урале уже несколько лет участвует
                        в организации конференций и курсов «ЗАВОДА».
                      </p>

                      <br />
                      <p>
                        Мы поддерживаем проекты «ЗАВОДА» на разных этапах — от коммуникации со спикерами, до дизайна и
                        организации самого события.
                      </p>

                      <br />
                      <div>
                        Что такое «ЗАВОД» и как он проходит, смотрите{' '}
                        <a
                          href="https://vk.com/video-205544983_456239083"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-8" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Участие в организации фестиваля музыки, искусства и уличный направлений «БЛИК»
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        «БЛИК» — это яркая точка, которая объединила творческих людей города и региона. Фестиваль дал
                        возможность проявить себя сообща и вдохновить на творчество более 4 000 людей.
                      </p>

                      <br />
                      <p>
                        Фестиваль проходил с 17 по 19 июня 2022 года в Челябинске на территории кластера Svoboda2 при
                        поддержке Министерства культуры Челябинской области, Фонда 2020 социальных, культурных и
                        образовательных инициатив и радиостанции Studio21.
                      </p>

                      <br />
                      <div>
                        Как прошел «Блик» смотрите{' '}
                        <a
                          href="https://vk.com/album-212951294_283276136"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          здесь
                        </a>{' '}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  <AccordionItem value="item-9" className="text-right">
                    <AccordionTrigger className="text-[16px] font-bold uppercase">
                      Интеграция брендов TUSA energy, VIP`s и JUNGLE SHOP в хип-хоп концерты и вечеринки
                    </AccordionTrigger>
                    <AccordionContent>
                      <p>
                        В рамках вечеринок хип-хоп комьюнити SWAHLIFE мы интегрировали бренды с учетом максимальной
                        пользы каждого бизнеса: для бренда TUSA energy был разработан специальный мерч к 3-хлетию
                        комьюнити и{' '}
                        <a
                          href="https://vk.com/hugoloud_swahlife?w=wall-223059335_68"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          разыгран необычным способом
                        </a>
                        ; Для VIP`s было создано и проведено мини-событие под эгидой бренда –{' '}
                        <a
                          href="https://geometria.ru/reportage/776037-vips-sketch-battle/"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          VIP`S SKETCH BATTLE
                        </a>
                        ; Для JUNGLE SHOP была реализована{' '}
                        <a
                          href="https://vk.com/album-221034826_295990828"
                          target="_blank"
                          className="border-b-2 border-second hover:border-[#bcbcbc] hover:text-[#bcbcbc]"
                        >
                          промо-съемка с продукцией магазина
                        </a>
                        , фотографии с которой в дальнейшем послужили оформлением для фото-зоны на вечеринке и концерте.
                      </p>

                      <br />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default HomePage;
