import Image from 'next/image';

export const Footer = () => {
  return (
    <footer className="bg-main py-[24px] text-[10px] font-bold text-second lg:text-[20px]">
      <div className="container  hidden items-center justify-between lg:flex">
        <div>© 2024 FRIENDS & HANDS</div>
        <Image alt="логотип" src="/images/footer-logo.svg" height={50} width={100} />
        <a className="xl:mr-[130px]" href="mailto:friends-hands@mail.ru">
          friends-hands@mail.ru
        </a>
      </div>
      <div className="container flex items-center justify-between lg:hidden">
        <a className="flex flex-col justify-between" href="mailto:friends-hands@mail.ru">
          <div>© 2024 FRIENDS & HANDS</div>
          <div className="xl:mr-[130px]">friends-hands@mail.ru</div>
        </a>
        <Image alt="логотип" src="/images/footer-logo.svg" height={50} width={100} />
      </div>
    </footer>
  );
};
