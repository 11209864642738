import { ReactNode } from 'react';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children } = props;
  return (
    <div className="flex h-full flex-col tracking-tight">
      <header>
        <div className="block">
          <Header />
        </div>
      </header>

      <div className="flex-1 bg-second">{children}</div>

      <Footer />
    </div>
  );
};
